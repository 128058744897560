import moment from 'moment';
import Vue from 'vue';
import {Module} from 'vuex';
import {Answer, Test, TestGroup, TestInstance} from '~/models';
import {api} from '~/util';

const UPDATE_THRESHOLD = 60000; // 60 sek

export interface TestState {
  questionIndex: number;
  activeTestLoaded: boolean;
  testInstance: TestInstance;
  testsFetchedAt: number;
  testGroupsFetchedAt: number;
  extraTriesFetchedAt: number;
  startTime: Date;
  answers: { [questionId: number]: number };
  autoSubmit: { dialog: boolean, testName: string, timeout: any };
}

export const testModule: Module<TestState, any> = {
  namespaced: true,
  state: {
    questionIndex: 0,
    activeTestLoaded: false,
    testInstance: null,
    testsFetchedAt: 0,
    testGroupsFetchedAt: 0,
    extraTriesFetchedAt: 0,
    startTime: null,
    answers: {},
    autoSubmit: {dialog: false, testName: '', timeout: null},
  },
  mutations: {
    START_TEST(state, testInstance: TestInstance) {
      state.questionIndex = 0;
      state.testInstance = testInstance;
      state.startTime = new Date(testInstance.createdAt);
      state.answers = {};
      localStorage.removeItem('test-index');
    },
    CONTINUE_TEST(state, testInstance: TestInstance) {
      state.testInstance = testInstance;
      const index = Number(localStorage.getItem('test-index') || 0);
      state.questionIndex = index < 0 ? 0 : index > testInstance.questions.length - 1 ? testInstance.questions.length - 1 : index;
      state.startTime = new Date(testInstance.createdAt);
      state.answers = testInstance.answers.reduce((obj, a) => ({...obj, [a.questionId]: a.id}), {});
    },
    CLEAR_TEST(state) {
      state.questionIndex = 0;
      state.testInstance = null;
      state.startTime = null;
      state.answers = {};
      localStorage.removeItem('test-index');
    },
    SET_ANSWER(state, answer) {
      const question = state.testInstance.questions[state.questionIndex];
      Vue.set(state.answers, question.id, answer.id);
    },
    NEXT_QUESTION(state) {
      state.questionIndex++;
      localStorage.setItem('test-index', String(state.questionIndex));
    },
    PREV_QUESTION(state) {
      state.questionIndex--;
      localStorage.setItem('test-index', String(state.questionIndex));
    },
  },
  actions: {
    async fetchAll(context) {
      if (Date.now() - context.state.testsFetchedAt > UPDATE_THRESHOLD) {
        context.state.testsFetchedAt = Date.now();
        const response = await api.get(`/api/test`);
        await Test.insert({data: response.data});
      }
    },
    async fetchAllGroups(context) {
      if (Date.now() - context.state.testGroupsFetchedAt > UPDATE_THRESHOLD) {
        context.state.testGroupsFetchedAt = Date.now();
        const response = await api.get(`/api/test/groups`);
        await TestGroup.insert({data: response.data});
      }
    },
    async fetchById(context, id) {
      const response = await api.get(`/api/test/${id}`);
      await Test.insert({data: response.data});
    },
    async getTestDetails(context, id) {
      const response = await api.get(`/api/test/${id}`);
      return response.data;
    },
    async getExtraTries(_context) {
      const response = await api.get('/api/test/extra-tries');
      return response.data;
    },
    async start({commit, state, dispatch}, payload: { testId: number; password: string }) {
      const response = await api.post(`/api/test/${payload.testId}/start`, {password: payload.password});
      await TestInstance.insert({data: response.data});
      const testInstance = await TestInstance.query().whereId(response.data.id).withAll().with('questions.answers|figures|explanationFigures').first();
      commit('START_TEST', testInstance);

      if (state.testInstance.timeLimit) {
        const diff = state.testInstance.timeLimit * 60000 - moment().diff(state.startTime) + 1500;
        state.autoSubmit.timeout = setTimeout(async () => {
          await dispatch('autoSubmit');
          await dispatch('clearTest');
        }, diff);
      }
    },
    async checkActive({commit, state, dispatch}) {
      const response = await api.put(`/api/test-instance/active`);

      if (response.data.instance) {
        await TestInstance.insert({data: response.data.instance});
        const testInstance = await TestInstance.query().whereId(response.data.instance.id).withAll().with('questions.answers|figures|explanationFigures').first();
        if (testInstance) {
          commit('CONTINUE_TEST', testInstance);

          if (state.testInstance.timeLimit) {
            const diff = state.testInstance.timeLimit * 60000 - moment().diff(state.startTime) + 1500;
            state.autoSubmit.timeout = setTimeout(async () => {
              await dispatch('autoSubmit');
              await dispatch('clearTest');
            }, diff);
          }
        }
      }

      state.activeTestLoaded = true;

      if (response.data.submitted) {
        state.autoSubmit.dialog = true;
        state.autoSubmit.testName = response.data.name;
        return {
          submitted: true,
          type: response.data.type,
          name: response.data.name,
        };
      }
    },
    async submit({state}) {
      clearTimeout(state.autoSubmit.timeout);
      const response = await api.put(`/api/test-instance/${state.testInstance.id}/submit`, state.answers);
      await TestInstance.insert({data: response.data});
    },
    async autoSubmit({state}) {
      clearTimeout(state.autoSubmit.timeout);
      const response = await api.put(`/api/test-instance/${state.testInstance.id}/submit`, state.answers);
      await TestInstance.insert({data: response.data});
      state.autoSubmit.dialog = true;
      state.autoSubmit.testName = state.testInstance.displayName;
    },
    async closeAutoSubmitDialog({state}) {
      state.autoSubmit.dialog = false;
    },
    async clearTest({commit}) {
      commit('CLEAR_TEST');
    },
    async setAnswer({state, commit}, answer: Answer) {
      const payload = {answerId: answer.id, questionId: answer.questionId, testInstanceId: state.testInstance.id};
      await api.post(`/api/test-instance/answer`, payload);
      commit('SET_ANSWER', answer);
    },
    async nextQuestion({commit}) {
      commit('NEXT_QUESTION');
    },
    async prevQuestion({commit}) {
      commit('PREV_QUESTION');
    },
  },
  getters: {
    question(state) {
      return state.testInstance?.questions[state.questionIndex];
    },
  },
};
