import {Module} from 'vuex';
import {TestInstance} from '~/models';
import {api} from '~/util';

export interface FeedbackState {
}

export const feedbackModule: Module<FeedbackState, any> = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async submitTestFeedback({commit, state, dispatch}, payload: { feedback, testId: number, testInstanceId: number }) {
      const response = await api.post(`/api/feedback/test`, payload);
      await TestInstance.update({where: payload.testInstanceId, data: {feedbackSubmitted: true}});
    },
  },
  getters: {},
};
