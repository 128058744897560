






















































import moment from 'moment';
import Vue from 'vue';
import LoginPanel from '~/components/auth/LoginPanel.vue';
import MessageDialog from '~/components/common/MessageDialog.vue';
import AppSidebar from '~/components/layout/AppSidebar.vue';
import AppToolbar from '~/components/layout/AppToolbar.vue';
import CatFooter from '~/components/layout/CatFooter.vue';

export default Vue.extend({
  components: {AppToolbar, AppSidebar, LoginPanel, CatFooter, MessageDialog},
  async created() {
    try {
      moment.locale(this.$i18n.locale);

      try {
        await this.$store.dispatch('auth/signInByToken');
      } catch (err) {
        // ignore failed token sign-up
      }

      if (this.auth.isAuthenticated) {
        const result = await this.$store.dispatch('test/checkActive');

        if (result && result.submitted && result.type === 'preExam') {
          await this.$store.dispatch('snackbar/showSnack', {msg: this.$t('msg.preExamSubmitted'), color: 'info', persistent: true});
        }
        await this.$store.dispatch('practice/continue');
      }
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    isIE() {
      return /MSIE|Trident/.test(window.navigator.userAgent);
    },
    student() {
      return this.$store.state.auth.user;
    },
    auth() {
      return this.$store.state.auth;
    },
    autoSubmit() {
      return this.$store.state.test.autoSubmit;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
    isLoading() {
      return this.$store.getters['app/isLoading'];
    },
  },
});
