






























import Vue from 'vue';

export default Vue.extend({
  name: 'CatFooter',
  methods: {
    openWindow(value: string) {
      window.open(value, '_blank');
    },
  },
});
