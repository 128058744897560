import {Module} from 'vuex';
import {Student} from '~/models';
import {api} from '~/util';

interface AuthState {
  isAuthPending: boolean;
  isAuthenticated: boolean;
  accessToken: string;
  refreshTimeoutId: any;
  user: Student | null;
  updateTime: Date;
}

export const authModule: Module<AuthState, any> = {
  namespaced: true,
  state: {
    isAuthPending: true,
    isAuthenticated: false,
    accessToken: '',
    refreshTimeoutId: 0,
    user: null,
    updateTime: new Date(),
  },
  mutations: {
    setAuthPending(state, isPending) {
      state.isAuthPending = isPending;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setProfile(state, user) {
      state.user = user;
      state.isAuthenticated = true;
      state.isAuthPending = false;
      state.updateTime = new Date();
    },
    signedOut(state) {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.user = null;
    },
  },
  actions: {
    async signIn({commit, dispatch}, credentials) {
      const response = await api.post('/api/auth/sign-in', credentials);
      await Student.insert({data: response.data.profile});
      const student = Student.query().whereId(response.data.profile.id).withAll().with('testGroups.tests').with('studentTestStates.test').first();
      commit('setAccessToken', response.data.token);
      commit('setProfile', student);
      dispatch('renewToken', response.data.token);
    },
    async signInByToken({commit, dispatch}) {
      try {
        const response = await api.post('/api/auth/refresh');
        await Student.insert({data: response.data.profile});
        const student = Student.query().whereId(response.data.profile.id).withAll().with('testGroups.tests').with('studentTestStates.test').first();
        commit('setAccessToken', response.data.token);
        commit('setProfile', student);
        dispatch('renewToken', response.data.token);
      } catch (err) {
        commit('setAuthPending', false);
      }
    },
    async signOut(context) {
      clearTimeout(context.state.refreshTimeoutId);
      await api.get('/api/auth/sign-out');
      context.commit('signedOut');
    },
    async refreshToken({commit, dispatch}) {
      const response = await api.post('/api/auth/refresh');
      commit('setAccessToken', response.data.token);
      dispatch('renewToken', response.data.token);
    },
    renewToken(context, token) {
      const payloadString = token.split('.')[1];
      const payload = JSON.parse(atob(payloadString));
      const expiresIn = payload.exp * 1000 - Date.now();

      // request fresh access-token 10s before it will expire
      context.state.refreshTimeoutId = setTimeout(() => context.dispatch('refreshToken'), expiresIn - 10000);
    },
    async updateUser({commit}) {
      const response = await api.post('/api/auth/update');
      await Student.insertOrUpdate({data: response.data});
      const student = Student.query().whereId(response.data.id).withAll().with('testGroups.tests').with('studentTestStates.test').first();
      commit('setProfile', student);
    },
  },
  getters: {},
};
