import {Model} from '@vuex-orm/core';
import {LicenceQuestion, Question, Subject} from '.';

export class Licence extends Model {
  static entity = 'licences';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      language: this.attr(''),
      subjects: this.hasMany(Subject, 'licenceId'),
      questions: this.belongsToMany(Question, LicenceQuestion, 'licenceId', 'questionId'),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: number;
  name: string;
  language: string;
  subjects: Subject[];
  questions: Question[];
  createdAt: string;
  updatedAt: string;

  get fullName() {
    return `${this.name} (${this.language})`;
  }

}
