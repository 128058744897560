










import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
  name: 'moment',
  props: {
    value: {default: false},
    type: {default: 'datetime'},
    tooltip: {},
  },
  computed: {
    formattedValue(): string {
      return this.format(this.$props.value, this.$props.type);
    },
    formattedTooltip(): string {
      return this.format(this.$props.value, this.$props.tooltip);
    },
  },
  methods: {
    format(value: string, type: string): string {
      switch (type) {
        case 'date':
          return this.date(value);
        case 'from-now':
          return this.fromNow(value);
        case 'datetime':
        default:
          return this.datetime(value);
      }
    },
    datetime(value: string) {
      return moment(value).format('DD.MM.YYYY - HH:mm:ss');
    },
    date(value: string) {
      return moment(value).format('DD.MM.YYYY');
    },
    fromNow(value: string) {
      return moment(value).fromNow();
    },
  },
});
