















import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {type: Boolean, default: false},
    title: {type: String, default: ''},
    text: {type: String, default: ''},
  },
  computed: {
    model: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
});
