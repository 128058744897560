import {Model} from '@vuex-orm/core';
import {Area, Licence, Question, SubjectQuestion} from '.';

export class Subject extends Model {
  static entity = 'subject';

  static fields() {
    return {
      id: this.attr(null),
      licenceId: this.attr(null),
      language: this.attr(''),
      number: this.attr(''),
      name: this.attr(''),
      areas: this.hasMany(Area, 'subjectId'),
      questions: this.belongsToMany(Question, SubjectQuestion, 'subjectId', 'questionId'),
      licence: this.belongsTo(Licence, 'licenceId'),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    };
  }

  id: number;
  licenceId: number;
  language: string;
  number: string;
  name: string;
  areas: Area[];
  questions: Question[];
  licence: Licence;
  createdAt: string;
  updatedAt: string;

  get fullName() {
    return `${this.number} - ${this.name}`;
  }
}
