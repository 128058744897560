export const de = {
  nav: {
    dashboard: 'Dashboard',
    tests: 'Tests',
    currentTest: 'Aktueller Test',
    currentPracticeTest: 'Aktuelle Test Übung',
    preExams: 'Pre-Exams',
    feedbackRequired: 'Feedback benötigt',
  },
  p: {
    correctAnswer: 'Richtige Antwort | Richtige Antworten',
    licence: 'Lizenz | Lizenzen',
    subject: 'Thema | Themen',
    area: 'Bereich | Bereiche',
    subArea: 'Unterbereich | Unterbereiche',
    question: 'Frage | Fragen',
    staticQuestion: 'Statische Frage | Statische Fragen',
    testDelivery: 'Test Abgabe | Test Abgaben',
    try: 'Versuch | Versuche',
    minute: 'Minute | Minuten',
    answer: 'Antwort | Antworten',
    student: 'Schüler | Schüler',
    test: 'Test | Tests',
    testGroup: 'Testgruppe | Testgruppen',
    testPractice: 'Testübung | Testübungen',
    preExam: 'Pre-Exam | Pre-Exams',
    progressTest: 'Progress-Test | Progress-Tests',
    finalTest: 'Final-Test | Final-Tests',
    pool: 'Pool | Pools',
  },
  msg: {
    latestUpdate: 'Zuletzt aktualisiert um {time} Uhr',
    error: {
      testStartFrom: 'Dieser Test ist vom {startDate} bis zum {endDate} für sie freigeschaltet.',
      testsNotActive: 'Die Tests sind vom {startDate} bis zum {endDate} für sie freigeschaltet.',
    },
    autoSubmit: {
      title: 'Test eingereicht',
      text: 'Der Test {name} wurde automatisch eingereicht, da sein Zeitlimit überschritten wurde.',
    },
    feedbackConfirmationDialog: {
      title: 'Feedback eingereicht',
      text: 'Vielen Dank für Ihre Unterstützung!',
    },
    feedbackDialog: {
      mandatory: {
        title: 'Ihre Meinung wird benötigt',
        text: `Herzlichen Glückwunsch zum Bestehen dieses Abschlusstests!

Aufgrund amtlicher Vorgaben müssen Sie noch ein Formular zur Bewertung Ihres Lernerlebnisses in dem behandelten Fachgebiet ausfüllen.

Sie können dieses Formular jetzt oder zu einem späteren Zeitpunkt ausfüllen, allerdings können Sie keine neuen Tests starten, bevor Ihre Meinung zu diesem Fachgebiet erfasst wurde.

Falls Sie das Formular zu einem späteren Zeitpunkt abgeben möchten, können Sie diese Seite jederzeit durch Auswahl der Option "Feedback benötigt" in der Seitenleiste aufrufen.`,
      },
      optional: {
        title: 'Ihre Meinung ist uns wichtig',
        text: `Herzlichen Glückwunsch zum Bestehen dieses Abschlusstests!

Falls Sie es wünschen, können Sie uns jetzt Ihre Meinung zu dem bestandenen Fachgebiet und Ihrem Lernerlebnis geben.

Falls Sie uns Ihre Meinung zu diesem Fachgebiet zu einem späteren Zeitpunkt geben möchten, können Sie dies jederzeit durch Aufrufen dieser Testergebnisse und die Auswahl des Buttons "Feedback abgeben" tun.`,
      },
    },
    mandatoryFeedbackFields: 'Alle Fragen mit einem * müssen beantwortet werden.',
    waitForFeedback: 'Du musst zuerst das ausstehende Feedback einreichen.',
    testSubmitted: 'Dein Test wurde eingereicht.',
    blockedByDependentTest: 'Du musst zuerst den Test bestehen, von dem dieser abhängig ist.',
    notAuthorized: 'Dieses Pre-Exam wurde noch nicht von deiner ATO für dich authorisiert.',
    preExamSubmitted: 'Dein Pre-Exam wurde erfolgreich an Deine Flugschule übermittelt. Du kannst Deine Ergebnisse im Pre-Exam Bereich einsehen. Falls die Einsicht in Deine Ergebnisse noch nicht gewährt wurde, wende Dich hierfür bitte an Deine Flugschule.',
    waitForAtoInsight: 'Warte auf ATO Auswertung',
    noPreExams: 'Dir sind aktuell keine Pre-Exams zugeordnet. Wenn Du bereit bist ein Pre-Exam zu beantragen, wende Dich bitte an Deine ATO.',
    noTests: 'Dir sind aktuell keine Tests zugeordnet.',
    noTriesLeft: 'Du hast alle verfügbaren Versuche verbraucht. Bitte kontaktiere deine Flugschule um einen weiteren Versuch freizuschalten.',
    noAnswerGiven: 'Nicht beantwortet',
    confirmSubmission: 'Du hast nur {0} von {1} beantwortet.{2}Bist du dir sicher, dass du den Test abgeben willst?',
    waitForCooldown: 'Du musst noch {duration} warten bevor du deinen nächsten Versuch starten kannst',
    noTestStartDate: 'Tests können nur während des Ausbildungszeitraums gestartet werden.',
    dashboard: {
      hello: `Hallo {studentName}.`,
      login: `Hallo {0},{1}Dein letzter Login war am {2}.`,
      latest: `Dein letzter Test war {0}, abgegeben am {1}. Dieser Test wurde {2}.`,
    },
  },
  label: {
    optional: 'Optional',
    text: 'Text',
    userType: 'Benutzer Typ',
    overall: 'Gesamt',
    submitted: 'Eingereicht',
    directlyAssignedTests: 'Direkt zugewiesene Tests',
    atoTests: 'Von ATO zugewiesene Tests',
    imprint: 'Impressum',
    insight: 'Einsicht',
    granted: 'Gewährt',
    denied: 'Entzogen',
    studentLogin: 'Schüleranmeldung',
    atoLogin: 'ATO Anmeldung',
    attention: 'Aufmerksamkeit',
    testConsistsOf: 'Dieser Test besteht aus den folgenden Sektionen',
    search: 'Suche',
    state: 'Zustand',
    all: 'Alle',
    explanation: 'Erklärung',
    test: 'Test',
    givenAnswer: 'Antwort',
    student: 'Schüler',
    result: 'Ergebnis',
    lastResult: 'Letztes Ergebnis',
    deliveredTests: 'Abgegebene Tests',
    assignedTests: 'Zugewiesene Tests',
    id: 'ID',
    login: 'Anmeldename',
    name: 'Name',
    forename: 'Vorname',
    surname: 'Nachname',
    validFrom: 'Start-Datum',
    validUntil: 'End-Datum',
    createdAt: 'Erstellt am',
    phoneNr: 'Telefon Nr.',
    schoolClass: 'Flugschulklasse',
    timeLimit: 'Zeitlimit',
    yourAnswer: 'Deine Antwort',
    unlimited: 'Unbegrenzt',
    time: 'Zeit',
    submittedTests: 'Abgegebene Tests',
    submittedPreExams: 'Abgegebene Pre-Exams',
    start: 'Start',
    stop: 'Stop',
    duration: 'Dauer',
    date: 'Datum',
    title: 'Titel',
    email: 'Email',
    loginName: 'Anmeldename',
    password: 'Passwort',
    rememberMe: 'Eingeloggt bleiben',
    clientLogin: 'Kundenlogin',
    passed: 'Bestanden',
    notPassed: 'Noch nicht bestanden',
    attempted: 'Offen',
    passRate: 'Prozentsatz zum Bestehen',
    submittedAt: 'Abgegeben am',
    percentage: 'Prozentsatz',
    dependentOn: 'Ist abhängig von',
    dependants: 'Hat abhängigen Test',
    status: 'Status',
    available: 'Verfügbar',
    locked: 'Gesperrt',
    correct: 'Richtig',
    incorrect: 'Falsch',
    coolDown: 'Wartezeit zwischen Versuchen',
    hours: 'Stunden',
    none: 'Keine',
    atoPreExams: 'ATO Pre-Exams',
    unauthorized: 'Nicht Authorisiert',
    authorized: 'Authorisiert',
    protected: 'Geschützt',
    activationCode: 'Aktivierungs-Code',
    actions: 'Aktionen',
    sort: 'Sortieren',
    ascending: 'Aufsteigend',
    type: 'Typ',
    startDate: 'Start Datum',
    endDate: 'End Datum',
    default: 'Standard',
  },
  action: {
    giveFeedback: 'Feedback Abgeben',
    close: 'Schließen',
    back: 'Zurück',
    reset: 'Zurücksetzen',
    login: 'Anmelden',
    logout: 'Abmelden',
    submit: 'Abschicken',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    prevItem: 'Vorheriger {item}',
    nextItem: 'Nächster {item}',
    submitItem: '{item} Abschicken',
    startItem: '{item} Starten',
    practiceItem: '{item} Üben',
    evaluateItem: '{item} auswerten',
    assignItem: '{item} zuweisen',
    manageItem: '{item} verwalten',
    removeItem: '{item} entfernen',
    authorizeAndStartTest: 'Aktivieren und test starten',
    apply: 'Anwenden',
    showItem: '{item} anzeigen',
    hideItem: '{item} verbergen',
    enableZoom: 'Zoom Aktivieren',
    disableZoom: 'Zoom Deaktivieren',
    maybeLater: 'Vielleicht später',
    submitNow: 'Jetzt abgeben',
    submitLater: 'Später abgeben',
    ok: 'OK',
    update: 'Aktualisieren',
    changeLanguage: 'Sprache ändern',
  },
  form: {
    isRequired: '{field} ist ein Pflichtfeld.',
    maxLength: '{field} darf nicht mehr als {max} Zeichen enthalten.',
    minLength: '{field} muss mindestens {min} Zeichen enthalten.',
    length: '{field} muss genau {length} Zeichen enthalten.',
    isInvalidEmail: '{field} ist keine gültige E-Mail Adresse.',
    isNumeric: '{field} muss einen Zahlenwert enthalten.',
  },
  error: {
    notAssigned: 'Dieser Test wurde dir nicht zugewiesen.',
  },
  feedback: {
    content: 'Wie hat Ihnen der Inhalt des Fachgebiets gefallen?',
    structure: 'Wie gut war der Inhalt strukturiert?',
    structureText: 'Wie könnten wir die Struktur des Inhalts verbessern?',
    didactics: 'Wie gut hat Sie der Inhalt auf den Abschlusstest vorbereitet?',
    didacticsText: 'Wie hätte Sie der Inhalt besser vorbereiten können?',
    completeness: 'Hat der Inhalt alle relevanten Bereiche des Fachgebiets abgedeckt?',
    completenessText: 'Welche Bereiche des Fachgebiets waren Ihrer Meinung nach nicht ausreichend abgedeckt?',
    comprehensibility: 'Wie eingänglich wurde das Fachgebiet behandelt?',
    comprehensibilityText: 'In welchen Bereichen hätten Sie Sich eine tiefere Aufbereitung der Inhalte gewünscht?',
    technicalCorrectness: 'Wie akkurat wurde das Fachgebiet Ihrer Meinung nach technisch und fachlich dargestellt?',
    technicalCorrectnessText: 'Welche technischen oder fachlichen Ungenauigkeiten sind Ihnen aufgefallen?',
    grammar: 'Wie klar und verständlich wurden die Inhalte kommuniziert?',
    grammarText: 'Wie hätten die Inhalte besser kommuniziert werden können?',
    difficulty: 'Hatten Sie Schwierigkeiten das Fachgebiet zu verstehen? (1=viele Schwierigkeiten ... 5=keine Schwierigkeiten)',
    difficultyText: 'Was waren die größten Schwierigkeiten für Ihr Verständnis des Fachgebiets?',
    advice: 'Hat Ihnen die Arbeitsanweisung dabei geholfen, das Fachgebiet in die übergreifende Struktur des Lehrgangs einzuordnen?',
    adviceText: 'Wie hätte die Arbeitsanweisungen Ihnen besser helfen können, das Fachgebiet im Verhältnis zum restlichen Lehrgang einzuordnen?',
    good: 'Was hat Ihnen an Inhalten des Fachgebiets besonders gefallen?',
    notGood: 'Wie könnten die Inhalte des Fachgebiets generell verbessert werden?',
    overall: 'Wie zufrieden waren Sie mit dem Fachgebiet im Allgemeinen?',
    overallText: 'Was hätte Sie Ihre Zufriedenheit mit diesem Fachgebiet erhöht?',
    answers: 'Wie würden Sie die Antwortmöglichkeiten des Abschlusstests beurteilen?',
    answersText: 'Was hat Sie am meisten an den möglichen Antworten gestört?',
    availability: 'Hatten Sie das Gefühl, bei Bedarf Hilfe oder Unterstützung zu erhalten oder unkompliziert erhalten zu können?',
    availabilityText: 'Wie wurden Ihre Erwartungen oder Anforderungen an die Verfügbarkeit von Unterstützung nicht erfüllt?',
    whatElse: 'Haben Sie noch andere Wünsche oder Ideen die Sie gerne in der Zukunft implementiert sehen würden? (Unabhängig von Art oder Stelle in der Ausbildung)',
    contactMe: 'Falls wir Sie zu diesem Feedback kontaktieren dürfen, über welche E-Mail Adresse oder Telefonnummer wären Sie am besten zu erreichen? (Falls Sie nicht kontaktiert werden möchten, lassen Sie dieses Feld bitte leer)',
  },
};
