import {Model} from '@vuex-orm/core';
import {SchoolTest, SchoolTestGroup, Test, TestGroup} from '~/models';
import {SchoolClass, Student} from './index';

export class School extends Model {
  static entity = 'schools';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      state: this.attr(''),
      logoUrl: this.attr(''),
      passRate: this.attr(''),
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
      lastLoginAt: this.attr(''),
      previousLoginAt: this.attr(''),
      students: this.hasMany(Student, 'schoolId'),
      classes: this.hasMany(SchoolClass, 'schoolId'),
      tests: this.belongsToMany(Test, SchoolTest, 'schoolId', 'testId'),
      testGroups: this.belongsToMany(TestGroup, SchoolTestGroup, 'schoolId', 'testGroupId'),
    };
  }

  id: string;
  name: string;
  state: string;
  passRate: number;
  logoUrl?: string;
  createdAt: string;
  updatedAt: string;
  lastLoginAt: string;
  previousLoginAt: string;
  students: Student[];
  classes: SchoolClass[];
  tests: Test[];
  testGroups: TestGroup[];
}
