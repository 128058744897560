import {Module} from 'vuex';

interface SnackbarStateInterface {
  show: boolean;
  msg: string;
  color: string;
  persistent: boolean;
}

interface SnackInterface {
  msg: string;
  color: string;
  persistent: boolean;
}

export const snackbarModule: Module<SnackbarStateInterface, any> = {
  namespaced: true,
  state: {
    show: false,
    msg: '',
    color: 'green',
    persistent: false,
  },
  mutations: {
    SHOW_SNACK: (state, snack: SnackInterface) => {
      state.show = true;
      state.color = snack.color;
      state.msg = snack.msg;
      state.persistent = snack.persistent || false;
    },
  },
  actions: {
    async showError({commit}, error: any) {
      const msg = error?.response?.data?.message || error;
      commit('SHOW_SNACK', {msg, color: 'error'});
    },
    async showSuccess({commit}, msg: string) {
      commit('SHOW_SNACK', {msg, color: 'success'});
    },
    async showInfo({commit}, msg: string, persistent = false) {
      commit('SHOW_SNACK', {msg, color: 'info'});
    },
    async showSnack({commit}, snack: SnackInterface) {
      commit('SHOW_SNACK', snack);
    },
  },
};
