
































import Vue from 'vue';
import LanguageChanger from '~/components/common/LanguageChanger.vue';
import {authRules} from './auth.rules';

export default Vue.extend({
  name: 'LoginPanel',
  components: {LanguageChanger},
  data() {
    return {
      tab: 0,
      isValid: false,
      form: {
        login: '',
        password: '',
        rememberMe: false,
      },
      rules: authRules.call(this),
    };
  },
  computed: {
    formsRef(): HTMLFormElement {
      return this.$refs.form as HTMLFormElement;
    },
  },
  methods: {
    async signIn() {
      if (this.formsRef.validate()) {
        try {
          await this.$store.dispatch('auth/signIn', this.form);
          const result = await this.$store.dispatch('test/checkActive');

          if (result && result.submitted && result.type === 'preExam') {
            await this.$store.dispatch('snackbar/showSnack', {msg: this.$t('msg.preExamSubmitted'), color: 'info', persistent: true});
          }
          await this.$store.dispatch('practice/continue');
        } catch (err) {
          await this.$store.dispatch('snackbar/showError', err);
        }
      }
    },
    async reset() {
      this.formsRef.reset();
      this.form.rememberMe = false;
    },
  },
});
