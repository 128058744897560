import {Model} from '@vuex-orm/core';

export class TestInstanceQuestion extends Model {
  static entity = 'test-instance_questions';

  static primaryKey = ['testInstanceId', 'questionId'];

  static fields() {
    return {
      testInstanceId: this.attr(null),
      questionId: this.attr(null),
    };
  }

  testInstanceId: number;
  questionId: number;
}
