



















import Vue from 'vue';
import {Student, TestInstance} from '~/models';
import Moment from '../common/Moment.vue';

export default Vue.extend({
  name: 'DashboardText',
  components: {Moment},
  data() {
    return {
      testsWithExtraTry: [],
    };
  },
  async created() {
    this.testsWithExtraTry = await this.$store.dispatch('test/getExtraTries');
  },
  computed: {
    user(): Student {
      return this.$store.state.auth.user;
    },
    latestInstance(): TestInstance {
      const testInstances = this.user.testInstances.filter(instance => !!instance.finishedAt && (!instance.isPreExam || instance.isPreExam && instance.insightGranted));
      if (testInstances.length === 0) {
        return null;
      }
      const latestTestInstanceInstance = testInstances.sort((a, b) => a.finishedAt < b.finishedAt ? 1 : b.finishedAt < a.finishedAt ? -1 : 0)[0];
      return TestInstance.query().whereId(latestTestInstanceInstance.id).withAll().first();
    },
    testInstances(): TestInstance[] {
      return TestInstance.query().withAll().all();
    },
  },
});
