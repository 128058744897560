import Vue from 'vue';
import {Module} from 'vuex';
import {Answer, Test, TestGroup, TestInstance} from '~/models';
import {api} from '~/util';

export interface PracticeState {
  questionIndex: number;
  activeTestLoaded: boolean;
  testInstance: TestInstance;
  startTime: Date;
  answers: { [questionId: number]: number };
  correctAnswers: number[];
}

export const practiceModule: Module<PracticeState, any> = {
  namespaced: true,
  state: {
    questionIndex: 0,
    activeTestLoaded: false,
    testInstance: null,
    startTime: null,
    answers: {},
    correctAnswers: [],
  },
  mutations: {
    START_TEST(state, testInstance: TestInstance) {
      state.questionIndex = 0;
      state.testInstance = testInstance;
      state.startTime = new Date(testInstance.createdAt);
      state.answers = {};
      localStorage.removeItem('test-index');
      localStorage.removeItem('test-answers');
      localStorage.setItem('practice', JSON.stringify(testInstance));
    },
    LOAD_ANSWERS(state) {
      state.answers = JSON.parse(localStorage.getItem('test-answers') || '{}');
    },
    SET_CORRECT_ANSWERS(state, answers) {
      state.correctAnswers = answers;
      state.testInstance.finishedAt = new Date().toISOString();
    },
    CONTINUE_TEST(state, testInstance: TestInstance) {
      state.testInstance = testInstance;
      const index = Number(localStorage.getItem('test-index') || 0);
      state.questionIndex = index < 0 ? 0 : index > testInstance.questions.length - 1 ? testInstance.questions.length - 1 : index;
      state.startTime = new Date(testInstance.createdAt);
      state.answers = JSON.parse(localStorage.getItem('test-answers') || '{}');
    },
    CLEAR_TEST(state) {
      state.questionIndex = 0;
      state.testInstance = null;
      state.startTime = null;
      state.answers = {};
      localStorage.removeItem('test-index');
      localStorage.removeItem('test-answers');
      localStorage.removeItem('practice');
    },
    SET_ANSWER(state, answer) {
      const question = state.testInstance.questions[state.questionIndex];
      Vue.set(state.answers, question.id, answer.id);
      localStorage.setItem('test-answers', JSON.stringify(state.answers));
    },
    NEXT_QUESTION(state) {
      state.questionIndex++;
      localStorage.setItem('test-index', String(state.questionIndex));
    },
    PREV_QUESTION(state) {
      state.questionIndex--;
      localStorage.setItem('test-index', String(state.questionIndex));
    },
  },
  actions: {
    async fetchAll(context) {
      const response = await api.get(`/api/test`);
      await Test.insert({data: response.data});
    },
    async fetchAllGroups(context) {
      const response = await api.get(`/api/test/groups`);
      await TestGroup.insert({data: response.data});
    },
    async fetchById(context, id) {
      const response = await api.get(`/api/test/${id}`);
      await Test.insert({data: response.data});
    },
    async getTestDetails(context, id) {
      const response = await api.get(`/api/test/${id}`);
      return response.data;
    },
    async getExtraTries(context) {
      const response = await api.get('/api/test/extra-tries');
      return response.data;
    },
    async practice({commit}, id: number) {
      const response = await api.post(`/api/test/${id}/practice`);
      commit('PRACTICE_TEST', response.data);
    },
    async start({commit}, id: number) {
      const response = await api.post(`/api/test/${id}/practice`);
      commit('START_TEST', response.data);
    },
    async continue({commit, state}) {
      const testInstance = JSON.parse(localStorage.getItem('practice'));
      if (testInstance) {
        commit('LOAD_ANSWERS');
        commit('CONTINUE_TEST', testInstance);
      }
      state.activeTestLoaded = true;
    },
    async submit({commit, state}) {
      const response = await api.put(`/api/test-instance/practice/evaluate`, Object.keys(state.answers));
      commit('SET_CORRECT_ANSWERS', response.data);
    },
    async clearTest({commit}) {
      commit('CLEAR_TEST');
    },
    async setAnswer({commit}, answer: Answer) {
      commit('SET_ANSWER', answer);
    },
    async nextQuestion({commit}) {
      commit('NEXT_QUESTION');
    },
    async prevQuestion({commit}) {
      commit('PREV_QUESTION');
    },
  },
  getters: {
    question(state) {
      return state.testInstance?.questions[state.questionIndex];
    },
  },
};
