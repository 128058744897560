
















































































import Vue from 'vue';
import Moment from '~/components/common/Moment.vue';
import {TestInstance} from '~/models';

export default Vue.extend({
  name: 'TestInstances',
  components: {Moment},
  data() {
    return {};
  },
  async created() {
    await this.$store.dispatch('testInstance/fetchAll');
  },
  computed: {
    instances(): TestInstance[] {
      const studentId = this.$store.state.auth.user.id;
      const instances = TestInstance.query().where('studentId', studentId).withAll().all();
      return instances.filter(instance => !instance.isPreExam && !!instance.finishedAt);
    },
    preExamInstances(): TestInstance[] {
      const studentId = this.$store.state.auth.user.id;
      const instances = TestInstance.query().where('studentId', studentId).withAll().all();
      return instances.filter(instance => instance.isPreExam && !!instance.finishedAt);
    },
    headers(): any[] {
      return [
        {text: this.$t('label.name'), value: 'displayName'},
        {text: this.$tc('p.try'), value: 'try', align: 'right'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers', align: 'right'},
        {text: this.$t('label.percentage'), value: 'percentage', align: 'right'},
        {text: this.$t('label.passed'), value: 'passed', align: 'center'},
        {text: this.$t('label.date'), value: 'finishedAt', align: 'right'},
      ];
    },
  },
  methods: {
    getColorClass(item: TestInstance, isPreExam = false) {
      if (item.passed) {
        return 'instance-green';
      }
      if (item.try < item.test.tries) {
        return 'instance-orange';
      }

      const instances = isPreExam ? this.preExamInstances : this.instances;

      const latestTestInstance = instances.filter(i => i.testId === item.testId).sort((a, b) => a.try < b.try ? 1 : a.try > b.try ? -1 : 0)[0];
      if (latestTestInstance.id === item.id && latestTestInstance.test.state !== 'archived') {
        return 'instance-red';
      }

      return 'instance-orange';
    },
  },
});
