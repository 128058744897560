import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardPage from '~/pages/DashboardPage.vue';

Vue.use(VueRouter);

const routes = [
  {path: '/', name: 'dashboard', component: DashboardPage},
  {path: '/tests', name: 'test-overview', component: () => import(/* webpackChunkName: "test-overview" */ '~/pages/TestOverviewPage.vue')},
  {path: '/progress-tests', name: 'progress-test-overview', component: () => import(/* webpackChunkName: "progress-test-overview" */ '~/pages/ProgressTestOverviewPage.vue')},
  {path: '/final-tests', name: 'final-test-overview', component: () => import(/* webpackChunkName: "final-test-overview" */ '~/pages/FinalTestOverviewPage.vue')},
  {path: '/pre-exams', name: 'pre-exam-overview', component: () => import(/* webpackChunkName: "pre-exam-overview" */ '~/pages/PreExamOverviewPage.vue')},
  {path: '/test/:id', name: 'test-details', component: () => import(/* webpackChunkName: "test-details" */ '~/pages/TestDetailPage.vue')},
  {path: '/test/:id/results', name: 'test-results', component: () => import(/* webpackChunkName: "test-results" */ '~/pages/TestResultsPage.vue')},
  {path: '/test', name: 'test', component: () => import(/* webpackChunkName: "test" */ '~/pages/TestPage.vue')},
  {path: '/practice', name: 'practice', component: () => import(/* webpackChunkName: "practice" */ '~/pages/PracticePage.vue')},
  {path: '/practice/results', name: 'practice-results', component: () => import(/* webpackChunkName: "practice-results" */ '~/pages/PracticeResultsPage.vue')},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
