export const en = {
  nav: {
    dashboard: 'Dashboard',
    tests: 'Tests',
    currentTest: 'Current Test',
    currentPracticeTest: 'Current Test Practice',
    preExams: 'Pre-Exams',
    feedbackRequired: 'Feedback required',
  },
  p: {
    correctAnswer: 'Correct Answer | Correct Answers',
    licence: 'Licence | Licences',
    subject: 'Subject | Subjects',
    area: 'Area | Areas',
    subArea: 'Sub area | Sub areas',
    question: 'Question | Questions',
    staticQuestion: 'Static Question | Static Questions',
    testDelivery: 'Test Delivery | Test Deliveries',
    try: 'Try | Tries',
    minute: 'Minute | Minutes',
    answer: 'Answer | Answers',
    student: 'Student | Students',
    test: 'Test | Tests',
    testGroup: 'Test Group | Test Groups',
    testPractice: 'Test Practice | Test Practices',
    preExam: 'Pre-Exam | Pre-Exams',
    progressTest: 'Progress-Test | Progress-Tests',
    finalTest: 'Final-Test | Final-Tests',
    pool: 'Pool | Pools',
  },
  msg: {
    latestUpdate: 'Last updated {time}',
    error: {
      testStartFrom: 'This Test is available from {startDate} until {endDate}.',
      testsNotActive: 'Tests are available from {startDate} until {endDate}.',
    },
    autoSubmit: {
      title: 'Test submitted',
      text: 'The test {name} has automatically been submitted, because its time limit has been exceeded.',
    },
    feedbackConfirmationDialog: {
      title: 'Feedback submitted',
      text: 'Thank you for your support!',
    },
    feedbackDialog: {
      mandatory: {
        title: 'Your Feedback is required',
        text: `Congratulations on passing this Final Test!

Due to regulatory requirements you must fill out a feedback form about the subject area of the test and the courseware material provided for it.

You can choose to submit this form now or at a later time, however you will not be able to start any new tests before it is submitted.

If you choose to postpone giving your feedback for now, you can always return to this screen by selecting the "Feedback required" button in the sidebar.`,
      },
      optional: {
        title: 'Your Feedback is appreciated',
        text: `Congratulations on passing this Final Test!

If you wish, you can now give us feedback about your learning experience in this subject.

If you want to give feedback at a later time, just come back to these Test Results and select the "Give Feedback" button.`,
      },
    },
    mandatoryFeedbackFields: 'All questions marked with an * are required for submission.',
    waitForFeedback: 'You must first submit the outstanding feedback.',
    testSubmitted: 'Your test has been submitted.',
    blockedByDependentTest: 'You must first pass the test, that this test is dependent on.',
    notAuthorized: 'This Pre-Exam has not yet been authorized by your ATO.',
    preExamSubmitted: 'Your Pre-Exam has been submitted to your flight school. You can check your results in the Pre-Exam area. If you have not yet been granted insight into your results, please contact your flight school.',
    waitForAtoInsight: 'Waiting for ATO review',
    noPreExams: 'You currently don\'t have pre-exams assigned to you. When you\'re ready to request a pre-exam please get in touch with your ATO.',
    noTests: 'You currently don\'t have tests assigned to you.',
    noTriesLeft: 'You\'ve used up all available tries for this test. Please get in touch with your flight school to request an additional try.',
    noAnswerGiven: 'No answer given',
    confirmSubmission: 'You have answered only {0} of {1}.{2} Are you sure you want to submit?',
    waitForCooldown: 'You have to wait for {duration} before you can attempt your next try',
    noTestStartDate: 'Tests can only be started within the Training Duration.',
    dashboard: {
      hello: `Hello {studentName}.`,
      login: `Hello {0},{1}Your last login was on {2}.`,
      latest: `Your most recent test was {0}, submitted on {1}. This test was {2}.`,
    },
  },
  label: {
    optional: 'Optional',
    text: 'Text',
    userType: 'User Type',
    overall: 'Overall',
    submitted: 'Submitted',
    directlyAssignedTests: 'Directly assigned tests',
    atoTests: 'By ATO assigned Tests',
    imprint: 'Imprint',
    insight: 'Insight',
    granted: 'Granted',
    denied: 'Denied',
    studentLogin: 'Student Login',
    atoLogin: 'ATO Login',
    attention: 'Attention',
    testConsistsOf: 'This test consists of the following sections',
    search: 'Search',
    state: 'State',
    all: 'All',
    explanation: 'Explanation',
    test: 'Test',
    givenAnswer: 'Given Answer',
    student: 'Student',
    result: 'Result',
    lastResult: 'Last Result',
    deliveredTests: 'Delivered Tests',
    assignedTests: 'Assigned Tests',
    id: 'ID',
    login: 'Login',
    name: 'Name',
    forename: 'Forename',
    surname: 'Surname',
    validFrom: 'Start Date',
    validUntil: 'End Date',
    createdAt: 'Created at',
    phoneNr: 'Phone Nr.',
    schoolClass: 'School Class',
    timeLimit: 'Time Limit',
    yourAnswer: 'Your Answer',
    unlimited: 'Unlimited',
    time: 'Time',
    submittedTests: 'Submitted Tests',
    submittedPreExams: 'Submitted Pre-Exams',
    start: 'Start',
    stop: 'Stop',
    duration: 'Duration',
    date: 'Date',
    title: 'Title',
    email: 'Email',
    loginName: 'Login Name',
    password: 'Password',
    rememberMe: 'Remember Me',
    clientLogin: 'Client Login',
    passed: 'Passed',
    notPassed: 'Not yet Passed',
    attempted: 'Open',
    passRate: 'Percentage to pass',
    submittedAt: 'Submitted at',
    percentage: 'Percentage',
    dependentOn: 'Dependent on',
    dependants: 'Dependants',
    status: 'Status',
    available: 'Available',
    locked: 'Locked',
    correct: 'Correct',
    incorrect: 'Incorrect',
    coolDown: 'Waiting period between tries',
    hours: 'Hours',
    none: 'None',
    atoPreExams: 'ATO Pre-Exams',
    unauthorized: 'Unauthorized',
    authorized: 'Authorized',
    protected: 'Protected',
    activationCode: 'Activation Code',
    actions: 'Actions',
    sort: 'Sort',
    ascending: 'Ascending',
    type: 'Type',
    startDate: 'Start Date',
    endDate: 'End Date',
    default: 'Default',
  },
  action: {
    giveFeedback: 'Give Feedback',
    close: 'Close',
    back: 'Back',
    reset: 'Reset',
    login: 'Login',
    logout: 'Logout',
    submit: 'Submit',
    cancel: 'cancel',
    confirm: 'Confirm',
    prevItem: 'Previous {item}',
    nextItem: 'Next {item}',
    submitItem: 'Submit {item}',
    startItem: 'Start {item}',
    practiceItem: 'Practice {item}',
    evaluateItem: 'Evaluate {item}',
    assignItem: 'Assign {item}',
    manageItem: 'Manage {item}',
    removeItem: 'Remove {item}',
    authorizeAndStartTest: 'Authorize and start test',
    apply: 'Apply',
    showItem: 'Show {item}',
    hideItem: 'Hide {item}',
    enableZoom: 'Enable Zoom',
    disableZoom: 'Disable Zoom',
    maybeLater: 'Maybe Later',
    submitNow: 'Submit Now',
    submitLater: 'Submit Later',
    ok: 'OK',
    update: 'Update',
    changeLanguage: 'Change Language',
  },
  form: {
    isRequired: '{field} is mandatory.',
    maxLength: '{field} may not be longer than {max} characters.',
    minLength: '{field} must contain at least {min} characters.',
    length: '{field} need to have a length of {length} characters.',
    isInvalidEmail: '{field} is not a valid email address.',
    isNumeric: '{field} needs to be a numeric value.',
  },
  error: {
    notAssigned: 'This test has not been assigned to you.',
  },
  feedback: {
    content: 'How did you like the content of the subject?',
    structure: 'How well was the content structured?',
    structureText: 'How can we improve the content structure?',
    didactics: 'How well did the content prepare you for the final test?',
    didacticsText: 'How could the content have prepared you better?',
    completeness: 'Did the content cover all relevant areas of the subject?',
    completenessText: 'Which areas did you feel we didn\'t cover adequately?',
    comprehensibility: 'How comprehensively was the subject covered?',
    comprehensibilityText: 'Which areas would you have liked to see covered in more detail?',
    technicalCorrectness: 'How technically correct was the subject presented to you?',
    technicalCorrectnessText: 'Which technical inaccuracies did you encounter in the subject?',
    grammar: 'How clear and concise was the subject communicated to you?',
    grammarText: 'How could the subject be communicated in a better manner?',
    difficulty: 'How challenging was the subject for you? (1=too difficult ... 5=very easy)',
    difficultyText: 'How could the subject be presented in a less challenging way?',
    advice: 'Did the Course Guide help you approach the subject in the overall structure of the course?',
    adviceText: 'How could the Course Guide have helped you approach the subject better in relation to other subjects?',
    good: 'What did you appreciate about the content of this subject overall?',
    notGood: 'How could the content of this subject be improved in general?',
    overall: 'How satisfied were you with the subject itself?',
    overallText: 'What made the subject unenjoyable to you?',
    answers: 'How would you rate the multiple choice answers?',
    answersText: 'What issues did you have with the available answers?',
    availability: 'Did you feel that help or support was easily available to you if needed?',
    availabilityText: 'How were your expectations for help or requirements for support not met?',
    whatElse: 'Do you have any other wishes or ideas that you would like to see us implement in the future regardless of area or part of the education?',
    contactMe: 'If you\'re open to being contacted about any part of this feedback, which email or phone number could you best be reached under? (Leave blank if you do not wish to be contacted about this feedback)',
  },
};
