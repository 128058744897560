import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import store from '~/store';

const api = axios.create({
  headers: {
    common: {
      Accept: 'text/plain, */*',
    },
  },
});

async function requestHandler(config: AxiosRequestConfig) {
  await store.dispatch('app/startRequest');
  // eslint-disable-next-line
  // @ts-ignore
  const token = store.state.auth.accessToken;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

async function responseHandler(res: AxiosResponse) {
  await store.dispatch('app/stopRequest');
  return res;
}

async function responseErrorHandler(err: AxiosError) {
  await store.dispatch('app/stopRequest');
  throw err;
}

api.interceptors.request.use(requestHandler);
api.interceptors.response.use(responseHandler, responseErrorHandler);

export {api};
