import {Module} from 'vuex';

interface AppState {
  openRequests: number;
  showSidebar: boolean;
}

export const appModule: Module<AppState, any> = {
  namespaced: true,
  state: {
    openRequests: 0,
    showSidebar: true,
  },
  mutations: {
    SHOW_SIDEBAR: (state, show) => {
      state.showSidebar = show;
    },
    START_REQUEST: (state) => {
      state.openRequests++;
    },
    STOP_REQUEST: (state) => {
      state.openRequests--;
    },
  },
  actions: {
    toggleSidebar({state, commit}) {
      commit('SHOW_SIDEBAR', !state.showSidebar);
    },
    startRequest({state, commit}) {
      commit('START_REQUEST');
    },
    stopRequest({state, commit}) {
      commit('STOP_REQUEST');
    },
  },
  getters: {
    isLoading(state) {
      return state.openRequests > 0;
    },
  },
};
