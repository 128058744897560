import {RuleGenerator} from '~/util/RuleGenerator';

export const authConstraints = {
  loginName: {isRequired: true},
  password: {isRequired: true, minLength: 6},
};

export function authRules(this: any) {
  return RuleGenerator.generateRules.call(this, authConstraints);
}
