import {Model} from '@vuex-orm/core';

export class LicenceQuestion extends Model {
  static entity = 'licence_questions';

  static primaryKey = ['licenceId', 'questionId'];

  static fields() {
    return {
      licenceId: this.attr(null),
      questionId: this.attr(null),
    };
  }

  licenceId: string;
  questionId: string;
}
