export class RuleGenerator {
  public static generateRules(this: any, constraints: any): any {
    const rulesObj: any = {};

    Object.keys(constraints).forEach(key => {
      const rules: any[] = [];
      const label = this.$t(`label.${key}`);
      const constraint = constraints[key];

      if (constraint.isRequired) {
        rules.push((v: string) => !!v || this.$t('form.isRequired', {field: label}));
      }
      if (constraint.maxLength) {
        rules.push((v: string) => !v || String(v).length <= constraint.maxLength || this.$t('form.maxLength', {field: label, max: constraint.maxLength}));
      }
      if (constraint.minLength) {
        rules.push((v: string) => !v || String(v).length >= constraint.minLength || this.$t('form.minLength', {field: label, min: constraint.minLength}));
      }
      if (constraint.length) {
        rules.push((v: string) => !v || String(v).length === constraint.length || this.$t('form.length', {field: label, length: constraint.length}));
      }
      if (constraint.isEmail) {
        rules.push((v: string) => RuleGenerator.EMAIL_REGEX.test(v) || this.$t('form.isInvalidEmail', {field: label}));
      }
      if (constraint.isNumeric) {
        rules.push((v: string) => !v || !isNaN(Number(v)) || this.$t('form.isNumeric', {field: label}));
      }

      rulesObj[key] = rules;
    });

    return rulesObj;
  }

  // eslint-disable-next-line no-useless-escape
  private static EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}
