import {Module} from 'vuex';
import {TestInstance} from '~/models';
import {api} from '~/util';

export interface TestInstanceState {
  finalTestDialog: boolean;
}

export const testInstanceModule: Module<TestInstanceState, any> = {
  namespaced: true,
  state: {
    finalTestDialog: false,
  },
  mutations: {
    showFinalTestDialog(state, value) {
      state.finalTestDialog = value;
    },
  },
  actions: {
    async fetchAll(context) {
      const response = await api.get(`/api/test-instance`);
      await TestInstance.insert({data: response.data});
    },
    async fetchById(context, id) {
      const response = await api.get(`/api/test-instance/${id}`);
      await TestInstance.insert({data: response.data});
      return response.data;
    },
    async showFinalTestDialog({commit}, show: boolean) {
      commit('showFinalTestDialog', show);
    },
  },
  getters: {},
};
