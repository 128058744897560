















import Vue from 'vue';
import {StudentTestState, Test, TestGroup, TestInstance} from '~/models';

export default Vue.extend({
  name: 'AppSidebar',
  data() {
    return {};
  },
  computed: {
    navigation(): any[] {
      return [
        {text: this.$t('nav.dashboard'), icon: 'mdi-home', to: {name: 'dashboard'}},
        {text: this.$t('nav.tests'), icon: 'mdi-text-box-multiple', to: {name: 'test-overview'}, condition: () => this.hasTests('default')},
        {text: this.$tc('p.progressTest', 3), icon: 'mdi-text-box-check', to: {name: 'progress-test-overview'}, condition: () => this.hasTests('progress')},
        {text: this.$tc('p.finalTest', 2), icon: 'mdi-file-certificate', to: {name: 'final-test-overview'}, condition: () => this.hasTests('final')},
        {text: this.$t('nav.preExams'), icon: 'mdi-certificate', to: {name: 'pre-exam-overview'}, condition: () => this.hasTests('preExam')},
        {text: this.$t('nav.currentTest'), icon: 'mdi-text-box-check', to: {name: 'test'}, condition: () => this.$store.state.test.testInstance !== null},
        {text: this.$t('nav.currentPracticeTest'), icon: 'mdi-text-box-check', to: {name: 'practice'}, condition: () => this.$store.state.practice.testInstance !== null},
        {
          text: this.$t('nav.feedbackRequired'),
          color: 'red',
          class: 'v-list-item--active',
          icon: 'mdi-message-alert',
          to: {name: 'test-results', params: {id: this.openMandatoryFeedback?.id}},
          condition: () => this.openMandatoryFeedback,
        },
      ];
    },
    app() {
      return this.$store.state.app;
    },
    openMandatoryFeedback() {
      const instance = TestInstance.all().find(instance => instance.feedback === 'mandatory' && !instance.feedbackSubmitted && instance.passed);
      if (instance?.finishedAt) {
        return instance;
      }
      return null;
    },
  },
  methods: {
    hasTests(type: string): boolean {
      const tests = Test.query().withAll().all().filter(test => test.students.find(s => s.id === this.$store.state.auth.user.id));
      const testGroupTests = TestGroup.query().withAll().all().reduce((arr, tg) => arr.concat(tg.tests), []);
      const testStateTests = StudentTestState.query().where('studentId', this.$store.state.auth.user.id).withAll().all().map(sts => sts.test);
      const allTests = tests.concat(testGroupTests).concat(testStateTests);
      const filteredTests = allTests.filter(test => test.type === type);
      return filteredTests.length > 0;
    },
  },
});
